import React from 'react'
import Helmet from 'react-helmet'
import '../../assets/scss/init.scss'

class Layout extends React.Component {
  render() {
    const { children, navBar, coverImage } = this.props

    return (
      <React.Fragment>
        {navBar}
        {coverImage}
        <div className="layout">
          <Helmet defaultTitle="Blog by John Doe" />
          {children}
        </div>
      </React.Fragment>
    )
  }
}

export default Layout
